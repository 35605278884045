import React from "react";
import styles from "./value-box.module.css";

function ValueBox({ value, isSelected, onClick }) {
  return (
    <button
      className={`${styles.valueBox} ${isSelected ? styles.selected : ""}`}
      onClick={onClick}
      type="button"
    >
      {value}
    </button>
  );
}

export default ValueBox;

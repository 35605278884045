import { useState } from "react";
import sendEmail from "../services/sendEmail";
import getEmailPayload from "../services/getEmailPayload";

const useSendQuoteRequest = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const sendQuoteRequest = async (formData) => {
    const controller = new AbortController();

    try {
      setLoading(true);
      setError(null);

      const emailPayload = getEmailPayload(formData);
      const result = await sendEmail(emailPayload, controller.signal);

      setData(result);
    } catch (error) {
      setError(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const reset = () => {
    setLoading(false);
    setError(null);
    setData(null);
  };

  return {
    sendQuoteRequest,
    reset,
    loading,
    error,
    data,
  };
};

export default useSendQuoteRequest;

const timeInputOptions = {
  days: { max: 6, label: "quote-request.units.days.plural" },
  weeks: { max: 3, label: "quote-request.units.weeks.plural" },
  months: { max: 2, label: "quote-request.units.months.plural" },
  trimester: { max: 1, label: "quote-request.units.trimester.singular" },
  semester: { max: 1, label: "quote-request.units.semester.singular" },
  year: { max: 1, label: "quote-request.units.year.singular" },
};

export default timeInputOptions;

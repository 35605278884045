import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import QuoteRequestPopup from "./quote-request-popup/quote-request-popup";

function Banner() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <section className="banner-one go-top">
      <div className="overlay">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="banner-one__content">
                <h3>
                  {t("home.title")}
                  <br /> {t("home.title2")}
                </h3>
                <p>{t("home.subtitle")}</p>
                <button
                  onClick={handleOpenPopup}
                  type="submit"
                  className="thm-btn d-flex align-items-center mx-auto"
                >
                  {t("home.button")}
                  <i className="fa fa-angle-double-right ms-2" />
                </button>
                {isPopupOpen && (
                  <QuoteRequestPopup
                    isPopupOpen={isPopupOpen}
                    handleClosePopup={handleClosePopup}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;

import SENDIBLUE_API_CONFIG from "./sendiblue-api-config";

const sendEmail = async (emailPayload, signal) => {
  const { URL, KEY } = SENDIBLUE_API_CONFIG;

  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": KEY,
      },
      body: JSON.stringify(emailPayload),
      signal,
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${
          errorData?.message || "Unknown error"
        }`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Email sending failed:", error);
    throw error;
  }
};

export default sendEmail;

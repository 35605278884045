import React from "react";
import styles from "./popup.module.css";

const Popup = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target.className === styles.popupOverlay) {
      onClose();
    }
  };

  return (
    <div className={styles.popupOverlay} onClick={handleOverlayClick}>
      <div className={styles.popupContent}>
        <button className={styles.popupClose} onClick={onClose}>
          <i className="far fa-times"></i>
        </button>
        {children}
      </div>
    </div>
  );
};

export default Popup;

import React, { useState, useEffect } from "react";
import Popup from "../../global-components/popup-component/popup";
import styles from "./quote-request-popup.module.css";
import quoteFormScenarios from "../../../data/quote-form-scenarios";
import useSendQuoteRequest from "../../../hooks/useSendQuoteRequest";
import { formatSimultaneousSimulations } from "../../../utils/formatters";
import { t } from "i18next";
import TimeInputGroup from "./time-input-group/time-input-group";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import Spinner from "../../global-components/spinner-component/spinner";

function QuoteRequestPopup({ isPopupOpen, handleClosePopup }) {
  const { sendQuoteRequest, reset, loading, error, data } =
    useSendQuoteRequest();
  const [formData, setFormData] = useState({
    fullName: "",
    institution: "",
    email: "",
    usageTime: 1,
    timeUnit: "months",
    simultaneousSimulations: 1,
    scenarios: quoteFormScenarios,
  });

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRangeChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: Number(value),
    }));
  };

  const handleScenarioChange = (scenarioId) => {
    setFormData((prev) => ({
      ...prev,
      scenarios: prev.scenarios.map((s) =>
        s.id === scenarioId ? { ...s, isChecked: !s.isChecked } : s
      ),
    }));
  };

  const handleUsageTimeChange = (newUsageTime) => {
    setFormData((prev) => ({
      ...prev,
      usageTime: newUsageTime,
    }));
  };

  const handleTimeUnitChange = (e) => {
    const { value: newUnit } = e.target;
    setFormData((prev) => ({
      ...prev,
      timeUnit: newUnit,
      usageTime: 1,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendQuoteRequest(formData);
    } catch (err) {}
  };

  return (
    <Popup isOpen={isPopupOpen} onClose={handleClosePopup}>
      {loading ? (
        <div className={styles.endMessageContainer}>
          <Spinner size="large" />
        </div>
      ) : error ? (
        <div className={styles.endMessageContainer}>
          <div className={styles.errorMessage}>
            <i className="far fa-times-circle" />
            {t("quote-request.error")}
          </div>
          <button className={styles.retryBtn} onClick={() => reset()}>
            {t("quote-request.buttons.retry")}
          </button>
        </div>
      ) : data ? (
        <div className={styles.endMessageContainer}>
          <div className={styles.successMessage}>
            <i className="far fa-check-circle" />
            <div>{t("quote-request.success")}</div>
          </div>
          <button
            className={styles.closeBtn}
            onClick={() => {
              reset();
              handleClosePopup();
            }}
          >
            {t("quote-request.buttons.close")}
          </button>
        </div>
      ) : (
        <div className={styles.quoteRequestPopup}>
          <h2 className={styles.title}>{t("quote-request.title")}</h2>
          <form className={styles.form} onSubmit={handleSubmit}>
            {/* Details Section */}
            <div className={styles.left}>
              <span className={styles.subtitle}>
                <i
                  className="far fa-info-circle"
                  style={{ marginRight: "8px", fontSize: "16px" }}
                />
                {t("quote-request.sections.details")}
              </span>

              {/* Usage Time */}
              <div className={styles.formGroup}>
                <label className={styles.label}>
                  {t("quote-request.labels.usage_time")}
                </label>
                <TimeInputGroup
                  timeUnit={formData.timeUnit}
                  usageTime={formData.usageTime}
                  onTimeUnitChange={handleTimeUnitChange}
                  onUsageTimeChange={handleUsageTimeChange}
                />
              </div>

              {/* Simultaneous Simulations */}
              <div className={styles.formGroup}>
                <label className={styles.label}>
                  {t("quote-request.labels.simultaneous_simulations")}{" "}
                  <Tooltip
                    html={
                      <div
                        style={{
                          fontSize: "12px",
                          padding: "0 6px",
                        }}
                      >
                        {t("quote-request.tooltips.simultaneous_simulations")}
                      </div>
                    }
                    position="top"
                    theme="light"
                    arrow={false}
                    size="small"
                  >
                    <i
                      className="far fa-info-circle"
                      style={{
                        marginLeft: "3px",
                        fontSize: "14px",
                        color: "orange",
                      }}
                    />
                  </Tooltip>
                </label>
                <input
                  type="range"
                  min={1}
                  max={10}
                  name="simultaneousSimulations"
                  value={formData.simultaneousSimulations}
                  onChange={handleRangeChange}
                  className={styles.rangeInput}
                />
                <span className={styles.rangeValue}>
                  {formatSimultaneousSimulations(
                    formData.simultaneousSimulations
                  )}
                </span>
              </div>

              {/* Scenarios */}
              <div className={styles.formGroup}>
                <label className={styles.label}>
                  {t("quote-request.labels.environments")}
                </label>
                <div className={styles.scenariosList}>
                  {formData.scenarios.map((scenario) => (
                    <label key={scenario.id} className={styles.scenarioLabel}>
                      <input
                        type="checkbox"
                        checked={scenario.isChecked}
                        onChange={() => handleScenarioChange(scenario.id)}
                        className={styles.scenarioCheckbox}
                      />
                      <span>
                        {t(`quote-request.scenarios.${scenario.key}`)}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            </div>

            {/* Divider */}
            <div className={styles.divider}></div>

            {/* Contact Section */}
            <div className={styles.right}>
              <span className={styles.subtitle}>
                <i
                  className="far fa-address-card"
                  style={{ marginRight: "8px" }}
                ></i>
                {t("quote-request.sections.contact")}
              </span>

              {/* Full Name */}
              <div className={styles.formGroup}>
                <label htmlFor="fullName" className={styles.label}>
                  {t("quote-request.labels.full_name")}{" "}
                  <span className={styles.required}>*</span>
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleTextChange}
                  placeholder={t("quote-request.labels.full_name")}
                  className={styles.input}
                  required
                  minLength="2"
                  maxLength="50"
                />
              </div>

              {/* Institution */}
              <div className={styles.formGroup}>
                <label htmlFor="institution" className={styles.label}>
                  {t("quote-request.labels.institution")}
                </label>
                <input
                  type="text"
                  id="institution"
                  name="institution"
                  value={formData.institution}
                  onChange={handleTextChange}
                  placeholder={t("quote-request.labels.institution")}
                  className={styles.input}
                  minLength="2"
                  maxLength="100"
                />
              </div>

              {/* Email */}
              <div className={styles.formGroup}>
                <label htmlFor="email" className={styles.label}>
                  {t("quote-request.labels.email")}{" "}
                  <span className={styles.required}>*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleTextChange}
                  placeholder={t("quote-request.labels.email")}
                  className={styles.input}
                  required
                  maxLength="254"
                />
              </div>
              <div className={styles.formButtons}>
                <button
                  type="button"
                  className={styles.cancelBtn}
                  onClick={handleClosePopup}
                >
                  {t("quote-request.buttons.cancel")}
                </button>
                <button type="submit" className={styles.submitBtn}>
                  {t("quote-request.buttons.submit")}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Popup>
  );
}

export default QuoteRequestPopup;

import { t } from "i18next";

export const formatUsageTime = (usageTime, timeUnit) => {
  const form = usageTime === 1 ? "singular" : "plural";
  return `${usageTime} ${t(`quote-request.units.${timeUnit}.${form}`)}`;
};

export const formatSimultaneousSimulations = (simultaneousSimulations) => {
  const form = simultaneousSimulations === 1 ? "singular" : "plural";
  const value =
    simultaneousSimulations === 10 ? "10+" : simultaneousSimulations;
  return `${value} ${t(`quote-request.units.simulation.${form}`)}`;
};

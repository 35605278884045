import { t } from "i18next";
import {
  formatUsageTime,
  formatSimultaneousSimulations,
} from "../utils/formatters";

const getEmailPayload = (formData) => {
  const {
    usageTime,
    timeUnit,
    simultaneousSimulations,
    scenarios,
    fullName,
    institution,
    email,
  } = formData;

  const selectedScenarioNames =
    scenarios
      .filter((scenario) => scenario.isChecked)
      .map((scenario) => t(`quote-request.scenarios.${scenario.key}`))
      .join(", ") || "None";

  const formattedUsageTime = formatUsageTime(usageTime, timeUnit);
  const formattedSimultaneousSimulations = formatSimultaneousSimulations(
    simultaneousSimulations
  );

  const payload = {
    sender: { name: "SIMOONS", email: "no-reply@simoonsvirtual.cat" },
    to: [{ email: "info@simoonsvirtual.com" }],
    subject: "New Quotation",
    htmlContent: `
      <h1>Quotation Request</h1>
      <p><strong>Usage Time:</strong> ${formattedUsageTime}</p>
      <p><strong>Simultaneous Simulations:</strong> ${formattedSimultaneousSimulations}</p>
      <p><strong>Scenarios:</strong> ${selectedScenarioNames}</p>
      <p><strong>Institution:</strong> ${institution || "None"}</p>
      <p><strong>User:</strong> ${fullName} (${email})</p>
    `,
  };

  return payload;
};

export default getEmailPayload;

import React from "react";
import { t } from "i18next";
import styles from "./time-input-group.module.css";
import { formatUsageTime } from "../../../../utils/formatters";
import timeInputOptions from "../../../../data/time-input-options";
import ValueBox from "./value-box/value-box";

function TimeInputGroup({
  usageTime,
  timeUnit,
  onUsageTimeChange,
  onTimeUnitChange,
}) {
  return (
    <>
      <div className={styles.timeInputGroup}>
        <div className={styles.valueBoxesContainer}>
          {[...Array(timeInputOptions[timeUnit].max)].map((_, index) => (
            <ValueBox
              key={index + 1}
              value={index + 1}
              isSelected={usageTime === index + 1}
              onClick={() => onUsageTimeChange(index + 1)}
            />
          ))}
        </div>
        <select
          value={timeUnit}
          onChange={onTimeUnitChange}
          className={styles.unitSelect}
        >
          {Object.entries(timeInputOptions).map(([value, { label }]) => (
            <option key={value} value={value}>
              {value === "year" || value === "trimester" || value === "semester"
                ? "1 "
                : ""}
              {t(label)}
            </option>
          ))}
        </select>
      </div>
      <span className={styles.rangeValue}>
        {formatUsageTime(usageTime, timeUnit)}
      </span>
    </>
  );
}

export default TimeInputGroup;

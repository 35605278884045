const quoteFormScenarios = [
  {
    id: "1",
    key: "urgencias_infecciosas",
    isChecked: false,
  },
  {
    id: "2", 
    key: "urgencias_adulto",
    isChecked: false,
  },
  {
    id: "3",
    key: "criticos_adulto",
    isChecked: false,
  },
  {
    id: "4",
    key: "unidad_criticos_cardiologicos",
    isChecked: false,
  },
  {
    id: "5",
    key: "urg_uci_pediatria",
    isChecked: false,
  },
  {
    id: "6",
    key: "laboratorios_clinicos",
    isChecked: false,
  },
];

export default quoteFormScenarios;
